/* eslint-disable no-lonely-if */
/* eslint-disable complexity */
/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */
import { Cartesian2, Event } from "../../Core/cesium/Source/Cesium.js";
import BoxEditTool from "./BoxEditTool";
import BoxTool from "./BoxTool";
import { EntityTypes, toolIds } from "./common";

import EventsHandler from "./EventHandler";
import LabelService from "./LabelService";
import SelectTool from "./SelectTool";

const toolTypesSet = new Set([toolIds.basic.box]);

export default class CesiumTools {
    constructor() {
        this.elementListeners = [];
        this.selectedToolEntities = [];
        this.persistentEntities = {};

        this.editShapeAllowed = true;
        this.selectedToolEntities = [];
        this.currentMousePosition = new Cartesian2();
        this.selectionEnabled = true;

        this.entitiesSelected = new Event();
        this.updatedEntities = new Event();
        this.evtEntitiesCreated = new Event();
    }

    setup(options) {
        this.viewer = options.viewer;
        this.labelService = new LabelService(this.viewer);

        options.cesiumEventHandler.addMouseMoveListenerAsFirst(() => {
            this.updateBoxEntities();
        });

        options.cesiumEventHandler.addMouseWheelListener(() => {
            this.updateBoxEntities();
        });

        this.eventsHandler = new EventsHandler(this, options.cesiumEventHandler);

        /*
        this.elementListeners.push({
            element: this.viewer?.canvas,
            eventType: "keydown",
            fn: this.keyDownHandler.bind(this)
        });
        this.elementListeners.push({
            element: this.viewer?.canvas,
            eventType: "keyup",
            fn: this.keyUpHandler.bind(this)
        });
        */

        this.viewer?.canvas.setAttribute("tabindex", "0");
        this.defaultTool = new SelectTool(toolIds.basic.select, this);
        this.defaultTool.setup(this.viewer);

        if (this.viewer) {
            // this.entityCreator = new EntityCreator(this);
        }

        this.elementListeners.push({
            element: window,
            eventType: "mousemove",
            fn: (r) => {
                if (!this.viewer?.cesiumWidget) return;
                const a = this.viewer.scene.canvas;
                const s = a.getBoundingClientRect();
                const h = r.clientX - s.left;
                const f = r.clientY - s.top;
                const m = h * (a.width / a.offsetWidth);
                const _ = f * (a.height / a.offsetHeight);

                if (m >= 0 && _ >= 0 && h < s.width && f < s.height) {
                    this.currentMousePosition.x = m;
                    this.currentMousePosition.y = _;
                }
            }
        });

        this.elementListeners.forEach((r) => r.element.addEventListener(r.eventType, r.fn, r.options));
    }

    get entitiesList() {
        return Object.values(this.persistentEntities);
    }

    get snapping() {
        return {
            alwaysSnap: true,
            cornerAngle: true,
            cornerGrid: true,
            cornerOtherShapeBorder: true,
            cornerOtherShapeCorner: true,
            cornerParalel: true,
            moveOtherShapeCorner: true
        };
    }

    get currentTool() {
        return this._currentTool;
    }

    selectTool(toolType) {
        if (this._currentTool?.type === toolType || !this.viewer?.cesiumWidget || this.isToolBeingSelectedJustNow) {
            return;
        }

        this.isToolBeingSelectedJustNow = true;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const selectedToolEntities = [...this.selectedToolEntities];

        try {
            const oldToolType = this.currentTool?.type || toolIds.basic.select;

            if (this.viewer?.canvas) {
                this.focus();
            }

            this.beforeSwitchTools();
            this._currentTool?.onDestroy();

            if (toolType == null) {
                // eslint-disable-next-line no-param-reassign
                toolType = toolIds.basic.select;
            }

            switch (toolType) {
                case toolIds.basic.box:
                    this._currentTool = new BoxTool(this);
                    break;
                case toolIds.basic.boxEdit:
                    this._currentTool = new BoxEditTool(this);
                    break;

                default:
                    this._currentTool = undefined;
            }

            if (this.currentTool) {
                this.currentTool.setup(this.viewer);

                if (oldToolType !== this.currentTool.type) {
                    this.emitToolChanged(this.currentTool.type);
                }
            } else {
                if (toolType === toolIds.basic.pluginTool) {
                    this.emitToolChanged(toolIds.basic.pluginTool);
                } else {
                    if (oldToolType !== toolIds.basic.select) {
                        this.emitToolChanged(toolIds.basic.select);
                    }
                }
            }

            if (toolTypesSet.has(toolType) || toolType === toolIds.basic.select || !toolType) {
                this.deselectAll(false);
            }
        } catch (r) {
            console.error(`Cannot select tool: ${toolType}`, r);
        } finally {
            this.isToolBeingSelectedJustNow = false;
        }
    }

    focus() {
        if (!new URLSearchParams(window.location.search)?.get("embedded") && !this.embedded) {
            this.viewer?.canvas.focus();
        }
    }

    beforeSwitchTools(n = true) {
        if (this.currentTool && n) {
            this.currentTool.cancelTool();
        }
    }

    selectCesiumEntity(entity, shiftPressed) {
        if (entity) {
            const foundEntity = this.findToolEntity(entity.id);

            if (foundEntity) {
                this.selectEntity(foundEntity, shiftPressed);
            } else {
                this.deselectAll();
                if (!entity?.properties?.luucy_actions) {
                    this.emitEntitiesSelected([]);
                }
            }
        } else this.deselectAll();
    }

    selectCesiumPrimitive(id, primitive, event, shiftPressed) {
        if (primitive) {
            const foundEntity = this.findToolEntity(id);

            if (foundEntity) {
                this.selectEntity(foundEntity, shiftPressed);
                // this.highlightService.highlightPrimitive(id, event, primitive);
            } else {
                console.error(`Cannot find cesium '${id}' primitive in tool entities`, primitive, this.persistentEntities);
            }
        } else {
            this.deselectAll();
        }
    }

    findToolEntity(n) {
        if (undefined === n) return;

        if (typeof n !== "string") return this.persistentEntities[n];

        const i = n.split(":");
        if (i.length < 2) return;
        const a = parseInt(i[1], 10);

        return this.persistentEntities[a];
    }

    entitiesUpdated(n) {
        this.updatedEntities.raiseEvent(n);
        /*
            this._shapesUpdatedEmitter.next(n),
            this.timerService.updateEntities(this);
      */
    }

    deselectAll(n = true) {
        for (const i of this.selectedToolEntities) i.selected = false;

        // this.emitEntitiesSelected([]);

        if (n) {
            this.selectTool(null);
        }

        this.selectedToolEntities = [];
        this.viewer?.scene.requestRender();
    }

    get selectedEntities() {
        return this.selectedToolEntities;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    findDefaultEntityName(entityType) {
        return "Box";
    }

    wipeEntities() {
        for (const i in this.persistentEntities) {
            this.removeEntityById(this.persistentEntities[i]?.id);
        }
    }

    entitiesCreated(entities) {
        for (const entity of entities) {
            this.removeEntityById(entity.id);
        }
        for (const i of entities) {
            if (this.persistentEntities[i.id]) throw new Error(`Entity with id ${i.id} already exists.`);
        }

        for (const i of entities) {
            this.persistentEntities[i.id] = i;
        }

        this.evtEntitiesCreated.raiseEvent(entities);
    }

    selectEntity(entity, shiftPressed) {
        // eslint-disable-next-line no-empty
        if (shiftPressed) {
        } else {
            for (const r of this.selectedToolEntities) {
                r.selected = false;
            }

            this.selectedToolEntities = [entity];
            this.doSelectSingleEntity(entity);
        }
    }

    doSelectSingleEntity(entity) {
        let toolId;

        switch (entity.type) {
            case EntityTypes.BOX:
                toolId = toolIds.basic.boxEdit;
                break;
        }

        if (toolId) {
            this.selectTool(toolId);
            this.currentTool.selectedEntity = entity;
        }
    }

    emitToolChanged(n) {
        // console.info(n);
    }

    updateBoxEntities() {
        const entities = this.entitiesList;

        entities.forEach((entity) => {
            entity.updateMeasurementLabels();
        });

        return false;
    }

    getEntityById(id) {
        const entities = this.entitiesList;

        for (let i = 0; i < entities.length; i++) {
            if (entities[i].id === id) {
                return entities[i];
            }
        }

        return undefined;
    }

    getEntityByName(name) {
        const entities = this.entitiesList;

        for (let i = 0; i < entities.length; i++) {
            if (entities[i].name === name) {
                return entities[i];
            }
        }

        return undefined;
    }

    removeEntity(name) {
        const entity = this.getEntityByName(name);

        if (!entity) {
            return;
        }

        delete this.persistentEntities[entity.id];

        entity.removeFromCesium(this.viewer);
    }

    removeEntityById(id) {
        const entity = this.getEntityById(id);

        if (!entity) {
            return;
        }

        delete this.persistentEntities[entity.id];

        entity.removeFromCesium(this.viewer);
    }
}
